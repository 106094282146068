const EntitiesMenu = [
  {
    heading: 'menu',
    route: '/course',
    pages: [
      {
        heading: 'Search Course',
        route: '/course/search-course',
        fontIcon: 'fa-search',
      },
      {
        heading: 'Add Course',
        route: '/course/courseInfo',
        fontIcon: 'fa-plus',
      },
      {
        heading: 'Running Course',
        route: '/course/running-course',
        fontIcon: 'fa-list',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
      {
        heading: 'Update Motor Driving List',
        route: '/course/motor-driving-list',
        fontIcon: 'fa-list',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
      {
        heading: 'Category-wise Course Report',
        route: '/course/category-wise-course',
        fontIcon: 'fa-list',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
      },
      {
        sectionTitle: 'Course Mapping',
        route: '/course/mapping',
        fontIcon: 'fa-plus',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        sub: [
          {
            heading: 'Map Trainer',
            route: '/course/mapping/trainer',
          },
          {
            heading: 'Map Assessor',
            route: '/course/mapping/assessor',
          },
        ],
      },
    ],
  },
];

export default EntitiesMenu;
